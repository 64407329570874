<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main">
        <ayl-table :table="table">
          <div slot="ctrl-button">
            <el-button type="success" class="btn-success" @click="add">新增</el-button>
          </div>
        </ayl-table>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      const vm = this
      return {
        nav: [
          {name: '系统管理', path: '/system-manage/permissions-allot'},
          {name: '权限分配'}
        ],
        table: {
          api: vm.$api.OrderList,
          query: {
            type: 2,
            carBlong: null,
            queryContent: null,
          },
          // 是否显示分页
          // hidePagination: true,
          // 表格查询的条件组件
          searchData: [
            {
              type: 'input',
              model: 'queryContent',
              placeholder: '角色'
            }],
          columns: [
            {
              title: '客户名称',
              key: 'partnerName',
            }, {
              title: '结算客户',
              key: 'partnerAccountName',
            }, {
              title: '运输日期',
              key: 'transportTime',
              filter: 'str2ymd',
            }, {
              title: '起运地',
              key: 'sendAddressDetail',
            }, {
              title: '目的地',
              key: 'receiveAddressDetail',
            }, {
              title: '驾驶员',
              key: 'driverName',
            }, {
              title: '提货车号',
              key: 'plateNumber',
            }, {
              title: '订单状态',
              key: 'orderStatus',
            }, {
              title: '订单编号',
              key: 'orderId',
            }, {
              title: '操作',
              width: '78px',
              render (h, ctx) {
                return h('span', [
                  h('span', {
                    class: {
                      'table-view': true,
                    },
                    on: {
                      click: vm.goAllot.bind(this, ctx.row)
                    }
                  }, '调度')
                ])
              }
            }]
        }
      }
    },
    methods: {
      /**
       * 调度
       */
      goAllot (val) {
        console.log(val)
        // this.$router.push({path:'/consign-order/record-order', query: {independent: false}});
        this.$router.push({path:'/outgoing-allot/allot', query: {orderId: val.orderId}});
      },
      add (){

      },
    },
    async mounted () {
      await this.$search(this.table)
    }
  }
</script>

<style lang='sass' scoped>

</style>
